import { MakeBackendRequest } from "./MakeBackendRequest";

export const getPaymentMethods = async (userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/payment_methods`,
      method: "get",
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const deletePaymentMethod = async (token, userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/payment_methods`,
      method: "delete",
      data: { token },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response;
  }
};

export const setDefaultPaymentMethod = async (token, userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/payment_methods`,
      method: "put",
      data: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const addPaymentMethod = async (nonce, userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/payment_methods`,
      method: "post",
      data: { paymentMethodNonce: nonce },
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};
