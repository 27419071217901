import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { TopThinBanner } from "../../components/topThinBanner/TopThinBanner";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Navbar } from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";

export const Layout = () => {
  const { isMobile } = useWindowDimensions();
  const location = useLocation();

  const showHeaderFooter = !(
    location.pathname.startsWith("/dashboard") && !isMobile
  );

  const Header = React.memo(() => (
    <>
      <TopThinBanner />
      <Navbar />
    </>
  ));

  return (
    <>
      {showHeaderFooter && <Header />}
      <Outlet />
      {showHeaderFooter && <Footer />}
    </>
  );
};

export default React.memo(Layout); // Memoize the Layout component
