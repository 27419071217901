import React, { useState } from "react";
import { deletePaymentMethod } from "../../../../services/paymentMethods";
import { useAppContext } from "../../../../context/AppContext";
import { ClipLoader } from "react-spinners";

const DeletePaymentMethod = ({ token, fetchPaymentMethods }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAppContext();
  const userId = user.id;

  const handleDelete = async (token) => {
    try {
      setIsLoading(true);
      await deletePaymentMethod(token, userId);
      await fetchPaymentMethods();
      alert("Payment method deleted successfully.");
    } catch (error) {
      console.error("Error deleting payment method:", error);
      alert("Failed to delete payment method.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={() => handleDelete(token)}
      className="px-[1rem] py-[0.5rem] text-sm font-medium bg-red-500 text-white rounded-full shadow"
    >
      {isLoading ? <ClipLoader size={16} color="white" /> : "Delete"}
    </button>
  );
};

export default DeletePaymentMethod;
