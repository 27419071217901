import React from "react";
const TabTitle = ({ title, subtitle, icon }) => {
  return (
    <div className="w-full border-b-[1px] border-b-ordersBorder mb-[30px]">
      <div className="flex space-x-2 items-center mb-2">
        <h2 className="text-primary text-[1.2rem] lg:text-[1.5rem] font-medium">
          {title}
        </h2>
        {icon && <img src={icon} className="" alt="svg" />}
      </div>
      <p className="text-base mb-[10px]">{subtitle}</p>
    </div>
  );
};

export default TabTitle;
