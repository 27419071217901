import React, { useState, useEffect } from "react";
import TabTitle from "../TabTitle";
import { getPaymentMethods } from "../../../../services/paymentMethods";
import { useAppContext } from "../../../../context/AppContext";
import { ClipLoader } from "react-spinners";
import AddPaymentMethod from "./AddPaymentMethod";
import SetAsDefault from "./SetAsDefault";
import DeletePaymentMethod from "./DeletePaymentMethod";

const PaymentTab = () => {
  const { user } = useAppContext();

  const [methodsList, setMethodsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const result = await getPaymentMethods(user.id);
      setMethodsList(result?.payment_methods);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchPaymentMethods();
    }
  }, [user]);

  const PaymentMethodCard = ({ method }) => {
    return (
      <div
        key={method.token}
        className={`relative bg-gradient-to-r ${
          method.default
            ? "from-primary-light to-primary"
            : "from-white to-gray-100"
        } rounded-xl shadow-md w-full md:w-[45%] h-[10rem] p-[1.5rem] flex flex-col justify-between`}
      >
        <div>
          <p className="text-xl font-bold uppercase">{method.card_type}</p>
          <p className="text-lg font-semibold tracking-wider mt-2">
            **** **** **** {method.last_4}
          </p>
          <p className="text-sm mt-1">Exp: {method.expiration_date}</p>
        </div>

        {method.default && (
          <p className="absolute top-3 right-3 text-xs font-bold bg-white text-primary px-2 py-1 rounded-full">
            Default
          </p>
        )}

        <div className="flex gap-4 mt-2">
          {!method.default && (
            <SetAsDefault
              fetchPaymentMethods={fetchPaymentMethods}
              token={method?.token}
            />
          )}
          <DeletePaymentMethod
            token={method?.token}
            fetchPaymentMethods={fetchPaymentMethods}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-w-[70rem] p-[1.25rem] lg:p-[1.87rem] border-[1px] border-ordersBorder flex flex-col items-start text-start">
      <TabTitle
        title="Your Payment Information"
        subtitle="Here’s where you can manage your payment preferences."
      />

      {loading ? (
        <div className="w-full flex items-center justify-center">
          <ClipLoader />
        </div>
      ) : (
        <div className="w-full flex flex-wrap gap-[1.5rem]">
          {methodsList.map((method) => (
            <PaymentMethodCard method={method} />
          ))}
        </div>
      )}

      <AddPaymentMethod fetchPaymentMethods={fetchPaymentMethods} />
    </div>
  );
};

export default PaymentTab;
