import { useState } from "react";
import SectionTitle from "../SectionTitle";
import PaymentTab from "./PaymentTab";
import PersonalTab from "./PersonalTab";
import BillingShippingTab from "./BillingShippingTab";
import SecurityTab from "./SecurityTab";

const TABS = {
  PERSONAL: "personal",
  SECURITY: "security",
  PAYMENT: "payment",
  BILLING_SHIPPING: "billingShipping",
};

const TABS_DATA = [
  { name: "Personal", value: TABS.PERSONAL },
  // TODO: Add back once backend ready for each tab
  // { name: "Security", value: TABS.SECURITY },
  { name: "Payment", value: TABS.PAYMENT },
  // { name: "Billing/Shipping", value: TABS.BILLING_SHIPPING },
];

const AccountSection = () => {
  const [selectedTab, setSelectedTab] = useState(TABS.PERSONAL);

  const renderTabContent = () => {
    switch (selectedTab) {
      case TABS.PERSONAL:
        return <PersonalTab />;
      case TABS.SECURITY:
        return <SecurityTab />;
      case TABS.PAYMENT:
        return <PaymentTab />;
      case TABS.BILLING_SHIPPING:
        return <BillingShippingTab />;
      default:
        return null;
    }
  };

  const TabsList = () => {
    return (
      <div className="flex mt-[30px] md:mt-0 pb-2 space-x-[1rem] mb-5 md:mb-3 overflow-x-scroll md:overflow-x-auto">
        {TABS_DATA.map((tab) => {
          const isSelected = tab.value === selectedTab;
          return (
            <button
              key={tab.value}
              type="button"
              className={`text-[1.12rem] px-4 py-2 ${
                isSelected
                  ? "bg-secondary text-darkPrimary font-semibold rounded-full"
                  : "text-black"
              }`}
              onClick={() => setSelectedTab(tab.value)}
            >
              {tab.name}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:px-[1.87rem] pb-[4rem] md:pb-[6rem] lg:pb-[8rem]">
      <SectionTitle title="Account Information" />
      <TabsList />
      {renderTabContent()}
    </div>
  );
};

export default AccountSection;
