import React, { useRef, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { addPaymentMethod } from "../../../../services/paymentMethods";
import dropin from "braintree-web-drop-in";
import { useAppContext } from "../../../../context/AppContext";

const AddPaymentMethod = ({ fetchPaymentMethods }) => {
  const { user } = useAppContext();
  const userId = user.id;
  const [isLoading, setIsLoading] = useState(false);
  const dropinInstance = useRef(null);

  useEffect(() => {
    if (!dropinInstance.current) {
      dropin.create(
        {
          authorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION_TOKEN,
          container: "#dropin-container",
          card: {
            cardholderName: true,
          },
          venmo: true,
        },
        (error, instance) => {
          if (error) {
            console.error("Error initializing Drop-in UI:", error);
            return;
          }
          dropinInstance.current = instance;
        }
      );
    }
  }, []);

  const handleAddPaymentMethod = async () => {
    if (!dropinInstance.current) {
      alert("Drop-in UI is not ready.");
      return;
    }
    try {
      setIsLoading(true);
      const { nonce } = await dropinInstance.current.requestPaymentMethod();
      await addPaymentMethod(nonce, userId);
      fetchPaymentMethods();
    } catch (error) {
      console.error("Error adding payment method:", error);
      alert("Failed to add payment method.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full mt-8">
      <h3 className="text-lg font-bold mb-4">Add New Payment Method</h3>
      <div id="dropin-container" className="mb-4"></div>
      <button
        onClick={handleAddPaymentMethod}
        className="px-4 py-2 bg-primary text-white rounded-full"
        disabled={isLoading}
      >
        {isLoading ? (
          <ClipLoader size={16} color="white" />
        ) : (
          "Add Payment Method"
        )}
      </button>
    </div>
  );
};

export default AddPaymentMethod;
