import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useAppContext } from "../../../context/AppContext";
import TextInput from "./FieldInputs/TextInput";
import DateInput from "./FieldInputs/DateInput";
import SelectInput from "./FieldInputs/SelectInput";
import TabTitle from "./TabTitle";
import { updateUser } from "../../../services/user";
import IdCardIcon from "../../../assets/icons/id-card.svg";
import AccessibilityIcon from "../../../assets/icons/accessibility.svg";
import Modal from "../../Modal";
import { MoonLoader } from "react-spinners";
import SurveyModal from "../../survey/SurveyModal/SurveyModal";

const PersonalTab = () => {
  const { user, setUser, signOutUser, modalState, setModalState } =
    useAppContext();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      first_name: user?.first_name || "",
      middle_initial: user?.middle_initial[0] || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      date_of_birth: user?.date_of_birth || "",
      sex: user?.sex || "",
      prefix: user?.prefix || "",
      pronouns: user?.pronouns || "",
      height_in_cm: user?.height_in_cm || "",
      weight_in_kg: user?.weight_in_kg || "",
    },
  });
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const updatedUser = {
        ...user,
        ...data,
      };
      // Todo: Once Backend Is Ready Then Allow Edit User Data
      await updateUser(user.id, updatedUser);
      setUser(updatedUser);
      setModalState({
        switch: true,
        message: "User updated successfully.",
        status: "success",
      });
    } catch (error) {
      console.error("Error updating user:", error);
      setModalState({
        switch: true,
        message: "Oops something went wrong...",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full text-start max-w-[70rem] border-[1px] border-ordersBorder p-[1.25rem] lg:p-[1.87rem] flex flex-col items-start"
        >
          <TabTitle
            title={"Your Personal Information"}
            subtitle={"Here’s where you can view and edit your personal info."}
            icon={IdCardIcon}
          />
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[3.75rem] mb-[3.12rem]">
            <TextInput label="First Name" name="first_name" required disabled />
            <TextInput label="Middle Initial" name="middle_initial" disabled />
            <TextInput label="Last Name" name="last_name" required disabled />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[3.75rem] mb-[3.12rem]">
            <TextInput
              label="Email Address"
              name="email"
              type="email"
              required
              className="col-span-1 md:col-span-2 max-w-none"
              disabled
            />
            <TextInput
              label="Phone Number"
              name="phone"
              type="number"
              required
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-[3.75rem] mb-[3.12rem]">
            <DateInput
              label="Date of Birth"
              name="date_of_birth"
              type="date"
              disabled
            />
          </div>
          <TabTitle
            title={"Additional Information"}
            subtitle={
              "Some further information such as sex, pronouns, allergies, etc."
            }
            icon={AccessibilityIcon}
          />
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[3.75rem] mb-[3.12rem]">
            <SelectInput
              label="Sex"
              name="sex"
              required
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Other Sex", label: "Other Sex" },
              ]}
            />
            <TextInput label="Prefix" name="prefix" />
            <SelectInput
              label="Preferred Pronouns"
              name="pronouns"
              required
              options={[
                { value: "He/Him", label: "He/Him" },
                { value: "She/Her", label: "She/Her" },
                { value: "They/Them", label: "They/Them" },
                { value: "Other Pronouns", label: "Other Pronouns" },
              ]}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[3.75rem] mb-[3.12rem]">
            <TextInput label="Height (cm)" name="height_in_cm" type="number" />
            <TextInput label="Weight (kg)" name="weight_in_kg" type="number" />
          </div>
          <div className="flex flex-col md:flex-row w-full justify-end space-y-[1rem] md:space-y-0 md:space-x-[1.87rem]">
            <button
              type="button"
              className="px-[2rem] lg:px-[4.56rem] py-[15px] text-base font-medium bg-secondary text-darkPrimary rounded-[10px]"
              onClick={signOutUser}
            >
              Logout
            </button>
            <button
              type="submit"
              className="px-[2rem] lg:px-[4.56rem] py-[15px] text-base font-medium bg-primary text-white rounded-[10px] flex items-center justify-center"
              disabled={loading}
            >
              {loading ? <MoonLoader size={20} /> : "Save Changes"}
            </button>
          </div>
        </form>
      </FormProvider>
      {modalState.switch && <SurveyModal />}
    </>
  );
};

export default PersonalTab;
