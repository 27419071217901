import React, { useState } from "react";
import { setDefaultPaymentMethod } from "../../../../services/paymentMethods";
import { useAppContext } from "../../../../context/AppContext";
import { ClipLoader } from "react-spinners";

const SetAsDefault = ({ fetchPaymentMethods, token }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppContext();
  const userId = user.id;

  const handleSetDefault = async (token) => {
    try {
      setIsLoading(true);
      await setDefaultPaymentMethod(token, userId);
      fetchPaymentMethods();
      alert("Payment method set as default.");
    } catch (error) {
      console.error("Error setting default payment method:", error);
      alert("Failed to set default payment method.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={() => handleSetDefault(token)}
      className="px-[1rem] py-[0.5rem] text-sm font-medium bg-white text-primary rounded-full shadow"
    >
      {isLoading ? <ClipLoader size={16} /> : "Set as Default"}
    </button>
  );
};

export default SetAsDefault;
