import { useFormContext } from "react-hook-form";

const SelectInput = ({ label, name, options, required = false, ...rest }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-col">
      <label className="text-base md:text-[1.12rem] font-medium md:font-light">
        {label}
      </label>
      <select
        {...register(name, {
          required: required ? `${label} is required` : false,
        })}
        className={`cursor-pointer  rounded-md py-1  bg-transparent ${
          errors[name] && "border-red-500 border"
        }`}
        {...rest}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && (
        <p className="text-red-500 text-sm">{errors[name].message}</p>
      )}
    </div>
  );
};

export default SelectInput;
