import { useEffect, useState } from "react";
import HelpSection from "../../components/Dashbaord/HelpSection";
import NotificationSection from "../../components/Dashbaord/NotificationSection";
import AccountSection from "../../components/Dashbaord/AccountSection";
import HomeSection from "../../components/Dashbaord/HomeSection";
import OdersSection from "../../components/Dashbaord/OdersSection";
import PharmacySection from "../../components/Dashbaord/PharmacySection";
import SubscriptionsSection from "../../components/Dashbaord/SubscriptionsSection";
import MDISurveySection from "../../components/MDISurveySection";
import MyProfile from "../../components/Dashbaord/MyProfile";
import { useAppContext } from "../../context/AppContext";
import { notifications, sections } from "../../utils/dashboard";
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Messages from "../Messages";
import Loader from "../../components/Loader";
import { Fade } from "react-awesome-reveal";
import { getPendingExternalSurveys } from "../../utils/survey";
import { Logo } from "../../components/logo/Logo";
import { loggedInHeaderRoutes } from "../../utils/routes";
import { CiSearch } from "react-icons/ci";
import Treatments from "./Treatments";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Navbar } from "../../components/navbar/Navbar";
import { signout } from "../../services/auth";
import { FiMail } from "react-icons/fi";
import DashboardHeader from "./DashboardHeader";

function DashboardTwo() {
  const { user, externalSurveys, resetAppContext, notifications, signOutUser } =
    useAppContext();
  const [pendingSurveys, setPendingSurveys] = useState(null);
  const location = useLocation();
  const isOnboarding = new URLSearchParams(location.search).get("onboarding");
  const navigate = useNavigate();

  useEffect(() => {
    setPendingSurveys(getPendingExternalSurveys(externalSurveys));
  }, [externalSurveys]);

  const loggedInItem = (item, index) => {
    if (!item.desktopVisibility) return <></>;
    return item.type === "link" ? (
      <NavLink key={index} to={item.href} className="relative">
        <img
          src={item.desktopIcon}
          alt={item.name}
          className="w-[2.2rem] h-[2.2rem] p-[.3rem] bg-secondary rounded-full"
        />
        {notifications?.filter((notification) => !notification?.read)?.length &&
        item.text === "Notifications" ? (
          <div
            className="w-5 h-5 bg-red-600 rounded-full absolute
              -bottom-1 -right-1 flex items-center justify-center"
          >
            <p className="text-white text-xs font-bold">
              {notifications?.length}
            </p>
          </div>
        ) : null}
      </NavLink>
    ) : (
      <div className="relative">
        <div
          className="flex items-center justify-center cursor-pointer"
          // onClick={() => handleNavItemClick(item)}
        >
          {item.text === "My Profile" ? (
            <>
              <div className="w-[.1rem] h-[2rem] bg-borderGray mr-3" />
              <p className="text-darkGray font-semibold italichover:underline">
                Hi, {user?.first_name || "User"}!
              </p>
              <RiLogoutCircleRLine
                onClick={signOutUser}
                className="mx-2 w-[1.3rem] h-[1.3rem]"
              />
            </>
          ) : (
            <img
              src={item.desktopIcon}
              alt={item.name}
              className="w-[2.2rem] h-[2.2rem] p-1 bg-secondary rounded-full"
            />
          )}
        </div>
      </div>
    );
  };

  const dashboardSideBar = () => {
    return (
      <div
        className="hidden md:flex min-h-screen bg-gradient-to-b from-primary to-darkPrimary p-[2rem]
      w-[18rem] flex flex-col items-start justify-start relative"
      >
        <div className="mb-[2rem]">
          <Logo />
        </div>

        <ul className="flex flex-col items-start justify-start w-full">
          {sections.map((section, index) => (
            <NavLink
              key={index}
              to={`/dashboard/${section.path}`}
              className={({ isActive }) =>
                `w-full text-left rounded-md p-3 mb-[.5rem] font-semibold transition-all duration-100 ${
                  isActive ? "text-darkPrimary bg-darkSecondary" : "text-white"
                }`
              }
            >
              <span className="mr-3">
                <img
                  className="w-5 inline"
                  src={
                    location.pathname?.includes(section.path)
                      ? section.activeIcon
                      : section.icon
                  }
                />
              </span>{" "}
              {section.text}
            </NavLink>
          ))}
        </ul>

        <hr className="my-8 bg-borderGray h-[.1rem] bg-opacity-10 border-0 w-full " />

        <p className="text-[12px] text-secondary">Quick Action</p>

        <NavLink
          to={`/dashboard/treatments`}
          className={({ isActive }) =>
            `w-full text-left rounded-md p-3 mb-[.5rem] font-semibold transition-all duration-100 ${
              isActive ? "text-darkPrimary bg-darkSecondary" : "text-white"
            }`
          }
        >
          <span className="mr-3">
            <CiSearch className="w-5 inline" />
          </span>{" "}
          Start New Treatment
        </NavLink>

        <NavLink
          to={`/dashboard/chat`}
          className={({ isActive }) =>
            `w-full text-left rounded-md p-3 mb-[.5rem] font-semibold transition-all duration-100 ${
              isActive ? "text-darkPrimary bg-darkSecondary" : "text-white"
            }`
          }
        >
          <span className="mr-3">
            <FiMail
              className={`w-5 h-5 inline mb-[0.2rem] ${
                location.pathname?.includes("chat")
                  ? "text-[#5144B4]"
                  : "text-[#F8DF75]"
              }`}
            />
          </span>{" "}
          Messages
        </NavLink>
      </div>
    );
  };

  return (
    <section className={`bg-white flex w-full overflow-y-auto`}>
      {pendingSurveys === null ? (
        <Loader />
      ) : pendingSurveys?.length ? (
        <MDISurveySection
          pendingSurveys={pendingSurveys}
          setPendingSurveys={setPendingSurveys}
          isOnboarding={isOnboarding}
        />
      ) : (
        <div className="w-full">
          <div className="flex w-full">
            {dashboardSideBar()}
            <div className="w-[90%] md:w-[80%] flex-1 bg-white mx-[1.2rem] md:mx-auto flex flex-col overflow-y-scroll">
              {/* Profile Section */}
              <div className="hidden md:flex w-full items-start justify-between p-5">
                {/* Left */}
                <div className="rounded-md px-[1rem] bg-[#F6F6F6] flex items-center justify-center">
                  <>
                    <CiSearch className="w-5 h-5 cursor-pointer hidden sm:inline" />
                    <input
                      className="w-44 py-[0.7rem] pl-2 bg-[#F6F6F6] outline-none text-[14px] hidden sm:inline"
                      placeholder="Search"
                    />
                  </>
                </div>

                {/* Right */}
                <div className="flex gap-4">
                  {loggedInHeaderRoutes.map((item, index) =>
                    loggedInItem(item, index)
                  )}
                </div>
              </div>
              <Routes>
                <Route
                  path="/*"
                  exact
                  element={
                    <Fade>
                      <HomeSection />
                    </Fade>
                  }
                />
                <Route path="/order" element={<OdersSection />} />
                <Route
                  path="/subscriptions"
                  element={<SubscriptionsSection />}
                />
                <Route path="/pharmacies" element={<PharmacySection />} />
                <Route path="/help" element={<HelpSection />} />
                <Route path={"/profile"} element={<MyProfile />} />
                <Route path="/chat" element={<Messages />} />
                <Route path="/treatments" element={<Treatments />} />
                <Route
                  path="/notifications"
                  element={<NotificationSection />}
                />
                <Route path="/account" element={<AccountSection />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default DashboardTwo;
