import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
import { signin } from "../../services/auth";
import { getUser } from "../../services/user";
import { useAppContext } from "../../context/AppContext";
import { loginFormData } from "../../utils/loginFormData";
import Loader from "../../components/Loader";

const LoginForm = ({ toggleForgotPassword, isForgotPassword }) => {
  const navigate = useNavigate();
  const { setAppState } = useAppContext();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const signedInUser = await signin(data);
      const userResponse = await getUser(signedInUser?.data?.id);
      setAppState(userResponse);
      navigate("/dashboard");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.errors ||
        error?.data?.errors ||
        error?.message ||
        "An unexpected error occurred.";

      setError("apiError", {
        type: "manual",
        message: errorMessage,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-[85%] md:w-full">
      <div className="flex flex-col items-center">
        <div className="w-full md:w-[70%] flex flex-col gap-[1rem] md:gap-[1.5rem] bg-darkPrimary md:bg-white p-[1.2rem] bg-opacity-60 rounded-[5px]">
          <div className="text-start text-[21px] text-white md:text-black md:text-[2rem] font-bold mb-[2.5rem] md:mb-0">
            Please Login
          </div>
          {loginFormData.map((field) => (
            <InputField
              key={field.name}
              type={field.type}
              placeholder={field.placeholder}
              register={register}
              rules={field.rules}
              error={errors[field.name]}
              name={field.name}
            />
          ))}
          {errors.apiError && (
            <p className="text-red-500 text-sm">{errors.apiError.message}</p>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={toggleForgotPassword}
              className="text-white md:text-primary mt-2 cursor-pointer text-[13px] underline md:text-[14px]"
            >
              {isForgotPassword ? "Login" : "Forgot Password?"}
            </button>
          </div>
          <div>
            {isSubmitting ? (
              <Loader />
            ) : (
              <button
                type="submit"
                className={`bg-secondary font-bold text-[12px] md:text-xl text-darkPrimary py-3 rounded-md md:rounded-full w-full md:w-[20rem]`}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
