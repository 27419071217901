import { MakeBackendRequest } from "./MakeBackendRequest";

export const getUser = async (userId) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/users/${userId}`,
    method: "get",
  });

  return response.data;
};

export const updateUser = async (userId, data) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/api/v1/users/${userId}`,
    method: "patch",
    data: {
      user: data,
    },
  });

  return response.data;
};
