import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";

const DateInput = ({
  label,
  name,
  className,
  required = false,
  inputStyles,
  ...rest
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchedDate = watch(name);

  useEffect(() => {
    if (watchedDate) {
      const formattedDate = moment(watchedDate).format("YYYY-MM-DD");
      setValue(name, formattedDate);
    }
  }, [watchedDate, setValue, name]);

  return (
    <div className={`flex flex-col max-w-[10rem] ${className}`}>
      <label className="text-base md:text-[1.12rem] font-medium md:font-light">
        {label}
      </label>
      <input
        {...register(name, {
          required: required ? `${label} is required` : false,
        })}
        type="date"
        defaultValue={watchedDate}
        className={`cursor-pointer rounded-md py-1 ${inputStyles} ${
          errors[name] && "border-red-500 border"
        } outline-none`}
        {...rest}
      />
      {errors[name] && (
        <p className="text-red-500 text-sm">{errors[name].message}</p>
      )}
    </div>
  );
};

export default DateInput;
