import { useFormContext } from "react-hook-form";

const TextInput = ({
  label,
  name,
  type = "text",
  className,
  required = false,
  inputStyles,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={`flex flex-col max-w-[10rem] ${className}`}>
      <label className="text-base md:text-[1.12rem] font-medium md:font-light">
        {label}
      </label>
      <input
        {...register(name, {
          required: required ? `${label} is required` : false,
        })}
        type={type}
        className={`cursor-pointer rounded-md max-w-[160px] py-1 ${inputStyles} ${
          errors[name] && "border-red-500 border"
        } outline-none`}
        {...rest}
        placeholder="Not Provided"
      />
      {errors[name] && (
        <p className="text-red-500 text-sm">{errors[name].message}</p>
      )}
    </div>
  );
};

export default TextInput;
