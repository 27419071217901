import React, {
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
} from "react";
import { SurveyStateContext } from "../SurveyContext";
import { statesArray } from "../../../constants/States";
import dropin from "braintree-web-drop-in";
import SubscriptionCard from "../SubscriptionCard";
import AddressForm from "./AddressForm";
import "./SurveyCreditCardField.scss";
import {
  paymentOptions,
  billingAddressFields,
  shippingAddressFields,
} from "../../../utils/payment";
import { termsContent } from "../../../utils/policy";
import { useAppContext } from "../../../context/AppContext";
import {
  FetchConsentPdf,
  DeleteConsentPdf,
} from "../../../services/fetchConsentPdf";
import { Document, Page } from "react-pdf";
import { ClipLoader } from "react-spinners";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { colors } from "../../../theme";
import { IoCloseSharp } from "react-icons/io5";

export const SurveyCreditCardField = (props) => {
  const {
    question,
    register,
    errors,
    getPaymentNonceRef,
    watch,
    setValue,
    unregister,
    trigger,
  } = props;

  const context = useContext(SurveyStateContext);
  const { userSurveyInfo } = useAppContext();
  const userSurveyId = userSurveyInfo?.userSurveyId;
  const userConsent = watch("user_consent");

  const [braintreeInstance, setBraintreeInstance] = useState();

  const [showConsentModal, setShowConsentModal] = useState();
  const [showTermsModal, setShowTermsModal] = useState();
  const [pdfUrl, setPdfUrl] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const [answers, setAnswers] = context;

  const billing_address_same_as_shipping =
    "billing_address_same_as_shipping_address";

  const [renderBillingFields, setRenderBillingFields] = useState(
    answers[billing_address_same_as_shipping]
      ? !answers[billing_address_same_as_shipping]
      : true
  );

  useEffect(() => {
    const initializeBraintree = () =>
      dropin.create(
        {
          authorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION_TOKEN,
          container: "#braintree-drop-in-div",
          card: {
            cardholderName: {
              required: true,
            },
          },
          venmo: {
            allowNewBrowserTab: false,
          },
        },
        function (error, instance) {
          if (error) console.error(error);
          else setBraintreeInstance(instance);
        }
      );

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }
  }, []);

  const handleBillingSameAsShipping = (event) => {
    const checked = event.target.checked;
    trigger();

    if (checked) {
      setAnswers({ ...answers, [billing_address_same_as_shipping]: true });
      setRenderBillingFields(false);
    } else {
      setAnswers({ ...answers, [billing_address_same_as_shipping]: false });
      setRenderBillingFields(true);
    }
  };

  const renderErrors = (fieldName) => {
    return (
      <>
        {errors[fieldName] && (
          <small className="flex justify-center text-red-700">
            {errors[fieldName].message}
          </small>
        )}
      </>
    );
  };

  useImperativeHandle(getPaymentNonceRef, () => ({
    async getNonce() {
      return new Promise((resolve, reject) => {
        if (braintreeInstance) {
          braintreeInstance.requestPaymentMethod(async (error, payload) => {
            if (error) {
              console.error(error);
              reject(error);
            } else {
              resolve(payload);
            }
          });
        } else {
          reject(new Error("Braintree instance not available"));
        }
      });
    },
  }));

  useEffect(() => {
    return () => {
      unregister(question.searchable_tag);
      trigger();
    };
  }, []);

  const fetchConsentPdf = async () => {
    try {
      const response = await FetchConsentPdf({ userId: userSurveyId });
      console.log(response.pdf);
      setPdfUrl(response.pdf);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setLoadingPdf(false);
    }
  };

  const modalPreviewHandler = async ({ modalType }) => {
    try {
      if (modalType === "consentModal") {
        setShowConsentModal(false);
      } else if (modalType === "termsModal") {
        setShowTermsModal(false);
      }
      await DeleteConsentPdf({ userId: userSurveyId });
    } catch (error) {
      console.log(error);
    }
  };

  const consentModalPreviewHandler = () => {
    setLoadingPdf(true);
    fetchConsentPdf();
    setShowConsentModal(true);
  };

  const handleConsentChange = async (e) => {
    if (!e.target.checked) {
      try {
        await DeleteConsentPdf({ userId: userSurveyId });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const consentModal = () => {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-50">
        <div
          onClick={() => modalPreviewHandler({ modalType: "consentModal" })}
          className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-25"
        />
        <div className="w-[95%] md:w-[80%] xl:w-[67%] h-[75%] rounded-[1.25rem] md:rounded-[10px] overflow-hidden bg-white shadow-md z-10 flex flex-col">
          <div className="border-b-[1px] border-b-borderGray bg-primary text-white text-start flex items-center justify-between py-[14px] lg:py-[1.2rem] px-[1.2rem] lg:px-[1.87rem]">
            <p className="text-[1rem] lg:text-[2rem] font-semibold">
              INFORMED CONSENT FOR TELEMEDICINE SERVICES
            </p>
            <span
              onClick={() => modalPreviewHandler({ modalType: "consentModal" })}
              className="cursor-pointer"
            >
              <IoCloseSharp size={24} color="white" />
            </span>
          </div>
          <div className="flex-1 w-full text-left lg:p-[2rem] h-full overflow-y-scroll overflow-x-hidden terms-scroll">
            {loadingPdf && (
              <div className="w-full flex items-center justify-center">
                <ClipLoader size={32} color={colors.primary} />
              </div>
            )}
            {pdfUrl && !loadingPdf && (
              <Document
                file={pdfUrl}
                renderMode="canvas"
                className={"flex flex-col items-center w-full"}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    className="w-full"
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            )}
          </div>
          <div className="text-center h-20 border-t-[1px] border-t-borderGray flex items-center justify-end">
            <button
              onClick={() => {
                setShowConsentModal(false);
                setShowTermsModal(true);
              }}
              type="button"
              className={`text-[1rem] lg:text-[1.5rem] mr-10 bg-secondary p-2 px-8 text-darkPrimary rounded-full disabled:opacity-50 disabled:cursor-not-allowed`}
              disabled={!numPages}
            >
              Agree
            </button>
          </div>
        </div>
      </div>
    );
  };

  const termsAndConditionsModal = () => {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-50">
        <div
          onClick={() => modalPreviewHandler({ modalType: "termsModal" })}
          className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-25"
        />
        <div className="w-[80%] xl:w-[67%] h-[75%] rounded-[1.25rem] md:rounded-[10px] overflow-hidden bg-white shadow-md z-10 flex flex-col">
          <div className="border-b-[1px] border-b-borderGray bg-primary text-white text-start flex items-center justify-between py-[14px] lg:py-[1.2rem] px-[1.2rem] lg:px-[1.87rem]">
            <p className="text-[1rem] lg:text-[2rem] font-semibold">
              Terms Of Service
            </p>
            <span
              onClick={() => modalPreviewHandler({ modalType: "termsModal" })}
              className="cursor-pointer"
            >
              <IoCloseSharp size={24} color="white" />
            </span>
          </div>
          <div className="flex-1 w-full overflow-y-scroll terms-scroll text-left p-[1rem] lg:p-[2rem] text-primary">
            {termsContent()}
          </div>
          <div className="text-center h-20 border-t-[1px] border-t-borderGray flex items-center justify-end">
            <button
              onClick={() => {
                setValue("user_consent", true, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
                setShowTermsModal(false);
              }}
              type="button"
              className="w-full lg:w-auto font-semibold lg:font-normal text-[1rem] lg:text-[1.5rem] lg:mr-10 bg-secondary py-[13px] lg:py-2 px-8 text-darkPrimary mx-[1rem] rounded-[1rem] lg:rounded-full"
            >
              Agree
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      {showConsentModal && consentModal()}
      {showTermsModal && termsAndConditionsModal()}
      <div className="w-full md:w-[80%] px-[4%] py-[1rem] md:py-[2rem] border-borderGraySmall lg:border-borderGray border-[1px] rounded-md mb-[3rem] relative">
        <p className="absolute top-[-10px] md:top-[-19px] left-1/2 transform -translate-x-1/2 text-[11px] md:text-[1.5rem] font-bold bg-[#f6f5fd] px-[1rem]">
          Explore Checkout
        </p>
        <div className="flex justify-between">
          {paymentOptions.map((option, index) => (
            <img
              key={index}
              src={option.src}
              alt={option.alt}
              className="w-[23%]"
            />
          ))}
        </div>
      </div>
      <SubscriptionCard />

      {/* Add Credit Card */}
      <div className="px-[4%] py-[2rem] bg-white border-borderGraySmall lg:border-borderGray border-[1px] rounded-md mb-[3rem] w-full">
        <div className="flex gap-[1rem] items-center">
          <p>Credit Card</p>
        </div>
        <div id={"braintree-drop-in-div"} />
      </div>

      {[shippingAddressFields, billingAddressFields].map((addressFields) => (
        <AddressForm
          fieldsObject={addressFields}
          statesArray={statesArray}
          register={register}
          renderErrors={renderErrors}
          renderBillingFields={renderBillingFields}
          handleBillingSameAsShipping={handleBillingSameAsShipping}
          billing_address_same_as_shipping={billing_address_same_as_shipping}
          watch={watch}
        />
      ))}

      {/* Dauchi Consent */}
      <div className="my-10 flex flex-col w-full items-start justify-start">
        <div className="relative flex items-center justify-start">
          <input
            type="checkbox"
            id="user_consent"
            name="user_consent"
            className="w-5 h-5 mx-2"
            {...register("user_consent", {
              required: "User Consent is required",
              onChange: (e) => handleConsentChange(e),
            })}
          />
          <label for="user_consent">
            I AGREE WITH <span className="underline">DAUCHI TERMS</span>
          </label>
          {watch("user_consent") === false && (
            <div
              onClick={() => consentModalPreviewHandler()}
              className="absolute top-0 left-0 w-full h-full bg-transparent z-20"
            />
          )}
        </div>
        {errors["user_consent"] && (
          <p className="text-red-700 my-2 pl-4">
            Please agree to the user consent
          </p>
        )}
      </div>
    </div>
  );
};
